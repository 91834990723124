import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "d-flex flex-wrap py-3 fs-6 fw-bold" }
const _hoisted_7 = { class: "flex-grow-1 text-gray-400" }
const _hoisted_8 = { class: "flex text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateTimeFormat = _resolveComponent("DateTimeFormat")!

  return (_ctx.data?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.data.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.schema.fields, (field, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-6",
                key: idx
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(field.label), 1),
                  _createElementVNode("div", _hoisted_8, [
                    (field.type==='DATE')
                      ? (_openBlock(), _createBlock(_component_DateTimeFormat, {
                          key: 0,
                          date: Number(_ctx.data.data[field.name])
                        }, null, 8, ["date"]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_ctx.data.data[field.name]), 1)
                        ], 64))
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}