
import {defineComponent, onMounted, ref, watch} from "vue";
import PaymentService from "@/core/services/PaymentService";
import store from "@/store";
import {LoadPanel} from "@/core/composite/composite";
import PaymentTypeLabel from "@/views/payment/PaymentTypeLabel.vue";
import PaymentStatus from "@/views/payment/PaymentStatusLabel.vue";
import Row from "@/components/base/common/Row.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import Timelines from "@/views/timeline/Timelines.vue";
import Notes from "@/views/note/Notes.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormNote from "@/views/activity/FormNote.vue";
import TagEntities from "@/views/tag/TagEntities.vue";
import Swal from "sweetalert2";

export default defineComponent({
  name: "PaymentPanel",
  components: {
    TagEntities,
    FormNote,
    BaseModal,
    QuickAction, Notes, Timelines, CurrencyFormat, Row, PaymentStatus, PaymentTypeLabel
  },
  props: {
    id: {type: String}
  },

  setup(props) {
    const note = ref({text: '', paymentId: ''})
    const refId = ref(props.id);
    const data = ref<any>({});
    const loadData = async (id) => {
      return await PaymentService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(refId.value);
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      note,
      data,
      ...LoadPanel(),
    }
  },
  methods: {
    onUpdateSanctionsStatus(id, status) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      PaymentService.sanctionsUpdate(id, status).then(res => {
        this.data = res
        const timeline = this.$refs.paymentTimelineRef as typeof Timelines;
        timeline.paginationLoad();
      }).finally(() => {
        Swal.close();
      })
    },

    onSave() {
      const baseModal = this.$refs.noteFormPayment as typeof BaseModal
      baseModal.hideBaseModal()
      const notes = this.$refs.paymentNoteRef as typeof Notes
      notes.paginationLoad();
    }
  }
})

