
import {defineComponent, onMounted, ref} from 'vue'
import Row from "@/components/base/common/Row.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import PolicyRemoteSearchService from "@/core/services/PolicyRemoteSearchService";
import store from "@/store";
import Swal from "sweetalert2";
import {useToast} from "vue-toast-notification";

export default defineComponent({
  name: "BHSIRemotePanel",
  components: {DateTimeFormat, Row},
  props: {
    id: {type: Object}
  },
  setup(props) {
    const policy = ref({});
    onMounted(async () => {
      await store.commit('SET_PANEL_LOADING', false)
    })
    const loadPolicy = (id) => {
      PolicyRemoteSearchService.getInsured(id).then(res => {
        policy.value = res;
      })
    }
    loadPolicy(props?.id?.client?.id)
    return {
      policy,
      loadPolicy,
    }
  },
  methods: {
    onImport() {
      Swal.showLoading()
      PolicyRemoteSearchService.createBSHIPolicy(this.id).then(res => {
        this.$router.push("/policies/" + res.policy.id)
        useToast().success('Save successfully', {position: 'top-right'})
      }).finally(() => {
        Swal.close()
      })
    }
  }
})
