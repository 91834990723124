import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body pt-3 pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateTimeFormat = _resolveComponent("DateTimeFormat")!
  const _component_Row = _resolveComponent("Row")!
  const _component_InsuredData = _resolveComponent("InsuredData")!

  return (_ctx.data?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.data.person.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Row, {
            row: [{name:'DOB', value:_ctx.data.person.dob, slot:true,key:'dob'},{name:'Gender', value:_ctx.data.person.gender}]
          }, {
            dob: _withCtx(() => [
              _createVNode(_component_DateTimeFormat, {
                date: _ctx.data?.person?.dob
              }, null, 8, ["date"]),
              _createTextVNode(" (" + _toDisplayString(_ctx.data?.person?.age) + " yo) ", 1)
            ]),
            _: 1
          }, 8, ["row"]),
          (_ctx.data.extraField)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_Row, {
                  row: [{name: 'Coverage', value: _ctx.data?.extraField?.coverage},
          {name: 'Number Of Days', value:_ctx.data?.extraField?.numberOfDays},
          ]
                }, null, 8, ["row"]),
                _createVNode(_component_Row, {
                  row: [{name: 'Province Of Plan Member', value: _ctx.data?.extraField?.provinceOfPlanMember},
          {name: 'Email', value:_ctx.data?.extraField?.email},
          ]
                }, null, 8, ["row"]),
                _createVNode(_component_Row, {
                  row: [{name: 'Employer Name', value: _ctx.data?.extraField?.employerName},
          {name: 'Relationship', value:_ctx.data?.extraField?.relationship},
          ]
                }, null, 8, ["row"])
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_InsuredData, {
            data: _ctx.data?.data?.importData
          }, null, 8, ["data"])
        ])
      ]))
    : _createCommentVNode("", true)
}