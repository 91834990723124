
import { defineComponent, onMounted, ref, watch } from "vue";
import Row from "@/components/base/common/Row.vue";
import InsuredType from "@/components/base/common/InsuredType.vue";
import PolicyStatus from "@/views/policy/PolicyStatus.vue";
import PolicyData from "@/views/policy/PolicyData.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import PolicyService from "@/core/services/PolicyService";
import AllCases from "@/views/policy/case/AllCases.vue";
import store from "@/store";
import TagEntities from "@/views/tag/TagEntities.vue";

export default defineComponent({
  name: "PolicyPanel",
  components: {TagEntities, AllCases, DateTimeFormat, PolicyData, PolicyStatus, InsuredType, Row},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id) => {
      return await PolicyService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data
    }
  }
})
