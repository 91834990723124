export const PolicyMenu = [
  {
    heading: "Case Management",
    pages: [
      {
        sectionTitle: "Policy",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "Search",
            route: "/policies",
          },
          {
            heading: 'Recent',
            route: '/list-policy/recent'
          },
          {
            heading: 'Watching',
            route: '/list-policy/watching'
          },
        ],
      },
      {
        sectionTitle: "Cases",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        open: true,
        sub: [
          {
            heading: "Search",
            route: "/cases",
          },
          {
            heading: "New Cases",
            route: "/new-cases",
          },
          {
            heading: 'My Cases',
            route: '/list-case/my-cases'
          },
          {
            heading: 'Recent',
            route: '/list-case/recent'
          },
          {
            heading: 'Watching',
            route: '/list-case/watching'
          },
          {
            heading: 'Deleted',
            route: '/list-case/deleted-cases',
            adminOnly: true
          }
        ],
      },
      {
        sectionTitle: "Claims",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        open: false,
        sub: [
          {
            heading: "Search",
            route: "/claims",
          },
          {
            heading: 'New Claims',
            route: '/new-claims'
          },
          {
            heading: 'Fast Track',
            route: '/claims-fast-track'
          },
          {
            heading: 'My Claims',
            route: '/list-claim/my-claims'
          },
          {
            heading: 'Recent',
            route: '/list-claim/recent'
          },
          {
            heading: 'Watching',
            route: '/list-claim/watching'
          },
          {
            heading: 'Deleted',
            route: '/list-claim/deleted-claims',
            adminOnly: true
          },

        ],
      },
      {
        sectionTitle: "Invoices",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        open: false,
        sub: [
          {
            heading: "Search",
            route: "/invoices",
          },
          {
            heading: 'New Invoices',
            route: '/new-invoices'
          },
          {
            heading: 'My Invoices',
            route: '/list-invoice/my-invoices'
          },
          {
            heading: 'Recent',
            route: '/list-invoice/recent'
          },
          {
            heading: 'Watching',
            route: '/list-invoice/watching'
          },
        ],
      },
      {
        sectionTitle: "Providers",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        open: false,
        sub: [
          {
            heading: "Search",
            route: "/providers",
          },
        ],
      },
    ],
  },
]
export const CaseMenu = [
  {
    heading: "case",
    route: "/cases",
    pages: [
      {
        heading: "Search",
        route: "/cases",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
        function: 'POLICY',
        action: 'VIEW'
      },
    ]
  }
]
export const Claims = [
  {
    heading: "Claims",
    pages: [
      {
        heading: "Dashboard",
        route: "/claims-dashboard",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Reminders Due",
        route: "/claim-reminders-due",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Claim Review Due",
        route: "/claim-review-due",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Approval Requests",
        route: "/claim-approval-requests",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      }
      /*
            ,
            {
              heading: 'Pending Activities',
              route: '/claim/pending-activities',
              svgIcon: "",
              fontIcon: "bi-app-indicator",
            }
      */
    ],
  },
]

export const CaseManagement = [
  {
    heading: "Case Management",
    pages: [
      {
        sectionTitle: "Inpatient",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "Cases",
            route: "/case-management/cases",
          },
          {
            heading: 'Hospitalizations',
            route: '/case-management/hospitalizations'
          }
        ],
      },
      {
        sectionTitle: "Outpatient",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "Cases",
            route: "/case-management/outpatient-cases",
          },
        ],
      },
    ],
  },
]
export const Assistance = [
  {
    heading: "Assistance",
    pages: [
      {
        sectionTitle: "Call Center",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "Dashboard",
            route: "/call-center/dashboard",
          },
          {
            heading: 'Phone Calls',
            route: '/call-center/search'
          }
        ],
      },
      {
        sectionTitle: "Emails",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          // {
          //   heading: "Cases",
          //   route: "/case-management/outpatient-cases",
          // },
        ],
      },
    ],
  },
]
export const AdminMenu = [
  {
    heading: "Admin",
    pages: [
      {
        heading: "Content Mapping",
        route: "/content-mapping",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Quicksight Report",
        route: "/quicksight-report",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Action Templates",
        route: "/action-templates",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Action Groups",
        route: "/action-template-groups",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },


    ],
  },
]
export const ToolMenu = [
  {
    heading: "Tools",
    pages: [
      {
        heading: "Companies",
        route: "/list-company/clients",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Users",
        route: "/users",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Login Audit",
        route: "/login-audit",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Teams",
        route: "/teams",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Templates",
        route: "/templates",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Notifications",
        route: "/notifications",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Lookups",
        route: "/lookups",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Tags",
        route: "/tags",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
    ],

  },
]

export const Finance = [
  {
    heading: "Payments",
    pages: [
      {
        sectionTitle: "Payments",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "Search",
            route: "/payments",
          },
          {
            heading: 'List',
            route: '/list-payment/recent',
          },
          {
            heading: 'Group Payments',
            route: '/group-payments',
          }
        ],
      },
      {
        heading: "Cheque Register",
        route: "/reports/cheque_register",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Client Fees",
        route: "/client-fees",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },

    ],
  },
]

export const Team = [
  {
    heading: "Team",
    pages: [
      {
        sectionTitle: "Team",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "Dashboard",
            route: "/team/dashboard",
          },
          {
            heading: 'Action Log ',
            route: '/team/action-log',
          },
          {
            heading: 'Scans',
            route: '/team/scans',
          },
        ],
      },
    ],
  },
  {
    heading: "Tasks",
    pages: [
      {
        sectionTitle: "Tasks",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "My Tasks",
            route: "/my-tasks",
          },
          {
            heading: 'My Team Tasks',
            route: '/team-tasks',
          },
          {
            heading: 'Search',
            route: '/task-search',
          },
        ],
      },
    ],
  },
  {
    heading: "Reminders",
    pages: [
      {
        sectionTitle: "Reminders",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "My Reminders",
            route: "/my-reminders",
          },
          {
            heading: 'Search',
            route: '/reminder-search',
          },
        ],
      },
    ],
  },
  {
    heading: "Actions",
    pages: [
      {
        sectionTitle: "Actions",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "COC",
            route: "/case-management/coc",
          },
        ],
      },
    ],
  },
  {
    heading: "Processes",
    pages: [
      {
        sectionTitle: "Processes",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "Medical Reviews",
            route: "/list-process/MEDICAL_REVIEW",
          },
          {
            heading: "Complaints",
            route: "/list-process/COMPLAINT",
          },
          {
            heading: "Inquiries",
            route: "/list-process/INQUIRY",
          },
          {
            heading: "Appeals",
            route: "/list-process/APPEAL",
          },
          {
            heading: "Issues",
            route: "/list-process/ISSUE",
          },
        ],
      },
    ],
  },

]

export const CustomerMenu = [
  {
    heading: "Customer",
    pages: [
      {
        heading: "Inbox",
        route: "/customers/inbox",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "New Customers",
        route: "/customers/new",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Confirmed Customers",
        route: "/customers/confirmed",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Pending Claims",
        route: "/customers/pending-claims",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
]